import React from 'react'

function IconPrev ({
    width,
    height
}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <g fill='#D82534' fillRule='evenodd'>
                <path d='M12.75 23.797v.5c3.51 0 6.7-1.354 9.01-3.549 2.31-2.194 3.74-5.239 3.74-8.599 0-3.361-1.43-6.406-3.74-8.6C19.45 1.354 16.26 0 12.75 0s-6.7 1.354-9 3.549C1.44 5.743 0 8.788 0 12.149c0 3.36 1.44 6.405 3.75 8.599 2.3 2.195 5.49 3.549 9 3.549v-1c-3.25 0-6.19-1.253-8.32-3.274C2.31 18.001 1 15.221 1 12.149c0-3.073 1.31-5.853 3.43-7.875C6.56 2.253 9.5 1 12.75 1c3.26 0 6.19 1.253 8.32 3.274 2.12 2.022 3.43 4.802 3.43 7.875 0 3.072-1.31 5.852-3.43 7.874-2.13 2.021-5.06 3.274-8.32 3.274v.5' />
                <path d='M7.83 11.642s-.86.512 0 1.024l7.16 4.273s.86.512.86-.488V7.857s0-1-.86-.488l-7.16 4.273' />
            </g>
        </svg>
    )
}

export default IconPrev
