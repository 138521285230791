import React from 'react'

import styles from './styles.module.scss'

export default function Button ({ children, href, previewLink, style }) {
    return (
        <a href={href} className={styles.button} data-preview-link={previewLink} style={style}>
            {children}
        </a>
    )
}
