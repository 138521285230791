/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import IconNext from './IconNext'
import IconPrev from './IconPrev'

import style from './styles.module.scss'
import logo from './images/logo.svg'

const Navigation = () => {
    return (
        <div className={style.navigation}>
            <a href='#' className='navigate-arrow navigate-right enabled'>
                <IconNext width={25} height={24} />
            </a>
            <a href='#/0' className={style.logo}>
                <img src={logo} alt=""/>
            </a>
            <a href='#' className='navigate-arrow navigate-left enabled'>
                <IconPrev width={25} height={24} />
            </a>
        </div>
    )
}

export default Navigation
