import React from 'react'
import RevealJs from 'reveal.js'
import CountrySelection from 'components/CountrySelection'
import SwiperCampaigns from 'components/SwiperCampaigns'
import { SwiperSlide } from 'swiper/react'
import queryString from 'query-string'

import Button from 'components/Button'
import Bubble from 'components/Bubble'
import Reveal from 'components/Reveal'
import Slide from 'components/Slide'
import Card from 'components/Card'

import alumniGift20203 from './images/alumni-gift-2020-3.png'
import alumniGift20202 from './images/alumni-gift-2020-2.png'
import alumniGift20201 from './images/alumni-gift-2020-1.png'
import commingSoon from './images/comming_soon.png'

const subNavigationStyles = [
    {
        position: 'absolute',
        bottom: '2.745em',
        left: '2.8em'
    },
    {
        position: 'absolute',
        bottom: '2.745em',
        left: '3.8em'
    },
    {
        position: 'absolute',
        bottom: '2.745em',
        left: '4.73em'
    },
    {
        position: 'absolute',
        bottom: '2.745em',
        left: '5.7em'
    },
    {
        position: 'absolute',
        bottom: '2.745em',
        left: '6.57em'
    }
]

function RenderSubNavigation ({ pathname }) {
    return subNavigationStyles.map((style, index) => {
        return (
            <Bubble
                key={index}
                href={index === 0 ? `${pathname}#/3` : `${pathname}#/3/${index}`}
                style={style}
            />
        )
    })
}

const Home = ({
    match,
    history
}) => {
    const { id } = match.params
    const { pathname, search } = history.location
    const [presentation, setPresentation] = React.useState(null)
    const { country } = queryString.parse(search)
    const countryUS = country === 'US'

    React.useEffect(() => {
        (async () => {
            const response = await window.fetch(`https://${process.env.REACT_APP_API_HOST}/api/presentation/${id}.json`)

            if (!response.ok) {
                window.alert('Unable to load presentation')
            }

            const json = await response.json()
            setPresentation(json.presentation)
            RevealJs.configure({ previewLinks: false }) // make reveal.js refresh the previewlink
        })()
    }, [id])

    return (
        <Reveal history={history}>
            <Slide backgroundUrl='/slides/01.jpg' />
            <Slide backgroundUrl='/slides/02.jpg' />
            <Slide backgroundUrl='/slides/03.jpg' />
            <Slide backgroundUrl='/slides/04.jpg'>
                <Slide backgroundUrl='/slides/04.jpg'>
                    <RenderSubNavigation pathname={pathname} />
                </Slide>
                <Slide backgroundUrl='/slides/04-01.jpg'>
                    <RenderSubNavigation pathname={pathname} />
                </Slide>
                <Slide backgroundUrl='/slides/04-02.jpg'>
                    <RenderSubNavigation pathname={pathname} />
                </Slide>
                <Slide backgroundUrl='/slides/04-03.jpg'>
                    <RenderSubNavigation pathname={pathname} />
                </Slide>
                <Slide backgroundUrl='/slides/04-04.jpg'>
                    <RenderSubNavigation pathname={pathname} />
                </Slide>
            </Slide>
            <Slide backgroundUrl='/slides/05.jpg' />
            <Slide backgroundUrl='/slides/06.jpg' />
            <Slide backgroundUrl='/slides/07.jpg'>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', paddingTop: '20%' }}>
                    <SwiperCampaigns history={history}>
                        <SwiperSlide style={{ maxWidth: '90px' }} />

                        <SwiperSlide>
                            <Card
                                src={alumniGift20202}
                                previewLink
                                href={presentation ? presentation.livePreview['higher_education-alumni_gift_2020_2'] : '#missing'}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card
                                src={alumniGift20203}
                                previewLink
                                href={presentation ? presentation.livePreview['higher_education-alumni_gift_2020_3'] : '#missing'}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card
                                src={alumniGift20201}
                                previewLink
                                href={presentation ? presentation.livePreview['higher_education-alumni_gift_2020_1'] : '#missing'}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card
                                src={commingSoon}
                                href='#missing'
                                // target={false}
                            />
                        </SwiperSlide>
                    </SwiperCampaigns>
                </div>
            </Slide>
            <Slide backgroundUrl='/slides/08.jpg' />
            <Slide backgroundUrl='/slides/09.jpg'>
                <div
                    style={{
                        position: 'absolute',
                        top: 875,
                        left: 118
                    }}
            >
                    <Button
                        href={presentation ? presentation.livePreview.crm : 'https://sandbox.shiftone.io/login'}
                        previewLink
                        style={{
                            width: '317px',
                            height: '35px'
                        }}
                    >
                        VIEW LIVE EXAMPLE
                    </Button>
                </div>
            </Slide>
            <Slide backgroundUrl={countryUS ? '/slides/10-US.jpg' : '/slides/10-CA.jpg'}>
                <CountrySelection />
            </Slide>
            <Slide backgroundUrl={countryUS ? '/slides/11-US.jpg' : '/slides/11-CA.jpg'}>
                <CountrySelection />
            </Slide>
            <Slide backgroundUrl='/slides/12.jpg' />
            <Slide backgroundUrl='/slides/13.jpg' />
        </Reveal>
    )
}

export default Home
