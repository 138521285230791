import React from 'react'

import styles from './styles.module.scss'

export default function Button ({
    children,
    href,
    previewLink,
    style
}) {
    return (
        <a
            href={href}
            className={styles.bubble}
            data-preview-link={previewLink}
            style={style}>
            {children}
        </a>
    )
}
